import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import { useEffect } from "react";
import {
  inactiveActiveProductService,
  listProductService,
} from "../../services/Register/ProductService";
import {
  deleteBenefitService,
  inactiveActiveBenefitService,
  listBenefitService,
} from "../../services/Register/BenefitService";

const BenefitListView = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({
    name: null,
    phone: null,
    state: null,
  });
  const [list, setList] = useState([]);

  const handleList = () => {
    setList([]);
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listBenefitService(filter)
      .then(({ data }) => {
        Swal.close();
        setList(data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = (id) => {
    history.push(`/admin/vantagem/${id}`);
  };

  const handleActiveInactive = (id) => {
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    inactiveActiveBenefitService(id)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro alterado com sucesso",
        });

        handleList();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja excluir? será irreversível",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, excluir",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Excluindo",
        text: "Por favor, aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      deleteBenefitService(id)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Registro removido com sucesso",
          });

          handleList();
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            <Card className="border-0 mb-5 mt-3 shadow">
              <CardHeader className="border-0 bg-white">
                <h4 className="headline-2">FILTRO - VANTAGENS</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Título</Label>
                      <Input
                        type="text"
                        onChange={(ev) =>
                          setFilter({ ...filter, title: ev.target.value })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="border-0 bg-white">
                <Button className="mr-2" color="default" onClick={handleList}>
                  BUSCAR
                </Button>
                <Button
                  color="success"
                  onClick={() => history.push("/admin/vantagem/novo")}
                >
                  NOVO REGISTRO
                </Button>
              </CardFooter>
            </Card>
            {list.length <= 0 && (
              <Alert color="primary" className="text-primary">
                Não foram identificados registros para esta consulta
              </Alert>
            )}
            {list.length > 0 && (
              <Card className="shadow">
                <CardHeader className="border-0 bg-white">
                  Lista de produtos
                </CardHeader>
                <Table
                  className={`table-striped table-borderless table-hover`}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>DATA CADASTRO</th>
                      <th>TITULO</th>
                      <th>STATUS</th>
                      <th>OPÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((register, key) => (
                      <tr key={key}>
                        <td>{register.createdAt}</td>
                        <td>{register.title}</td>
                        <td>
                          {register.active == 1 ? (
                            <Badge color="success">ATIVO</Badge>
                          ) : (
                            <Badge color="danger">INATIVO</Badge>
                          )}
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>Opções</DropdownToggle>

                            <DropdownMenu>
                              <DropdownItem header>Opções</DropdownItem>
                              <DropdownItem
                                onClick={() => handleUpdate(register.id)}
                              >
                                ALTERAR
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleActiveInactive(register.id)
                                }
                              >
                                {register.active > 0 ? "INATIVAR" : "ATIVAR"}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => handleDelete(register.id)}
                              >
                                EXCLUIR
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BenefitListView;
