import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { listCategoryService } from "../../../services/Register/CategoryService";
import { listProductService } from "../../../services/Register/ProductService";

const RelatedLinksComponent = ({ relatedLinks = [], setRelatedLinks }) => {
  const [typeLink, setTypeLink] = useState("");
  const [listSelect, setListSelect] = useState([
    { id: "", title: "Link Externo" },
  ]);
  const handleAdd = () => {
    setRelatedLinks([
      ...relatedLinks,
      { id: null, title: "", description: "", link: "" },
    ]);
  };

  const handleRemove = (key) => {
    if (relatedLinks.length < 0) {
      Swal.fire({
        icon: "warning",
        title: "Ops...",
        text: "Você não pode remover todos os agrupamentos!",
      });
      return;
    }

    setRelatedLinks(
      relatedLinks.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  const handleListCategory = (value, key) => {
    const newData = [...relatedLinks];
    relatedLinks[key].typeLink = value;
    if (value === 0) {
      relatedLinks[key].listSelect = [{ id: "", name: "Link Externo" }];
      setRelatedLinks(newData);
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listCategoryService({ active: 1 })
      .then(({ data }) => {
        Swal.close();
        relatedLinks[key].listSelect = data?.resultSet;
        setRelatedLinks(newData);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListProduct = (categoryId, key) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listProductService({ categoryId: categoryId, active: 1 })
      .then(({ data }) => {
        Swal.close();
        const newData = [...relatedLinks];
        relatedLinks[key].categorySelected = categoryId;
        relatedLinks[key].products = data?.resultSet;
        setRelatedLinks(newData);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Card className="border-0 shadow mt-4">
        <CardHeader className="border-0 bg-white">
          <h5>Links relacionados</h5>
        </CardHeader>
        <CardBody>
          {relatedLinks.length > 0 && (
            <>
              {relatedLinks.map((relatedLink, key) => (
                <Card className="shadow mb-4" key={`relatedLinks${key}`}>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <Label>Tipo</Label>
                          <select
                            className="form-control"
                            value={relatedLinks[key]?.typeLink}
                            onChange={(ev) => {
                              handleListCategory(Number(ev.target.value), key);
                            }}
                          >
                            <option value="">Selecione...</option>
                            <option value={0}>Link externo</option>
                            <option value={1}>Produto</option>
                            <option value={2}>Categoria</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {relatedLinks[key]?.typeLink === 1 && (
                        <>
                          <Col md="4">
                            <FormGroup>
                              <Label>Categoria</Label>
                              <InputGroup>
                                <select
                                  className="form-control"
                                  value={relatedLinks[key]?.categorySelected}
                                  onChange={(ev) => {
                                    handleListProduct(ev.target.value, key);
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  {relatedLinks[key]?.listSelect?.map(
                                    (item, key) => (
                                      <option value={item.id} key={key}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup>
                              <Label>Produto</Label>
                              <InputGroup>
                                <select
                                  className="form-control"
                                  value={relatedLinks[key]?.productSelected}
                                  onChange={(ev) => {
                                    const newData = [...relatedLinks];
                                    relatedLinks[key].productSelected =
                                      ev.target.value;
                                    setRelatedLinks(newData);
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  {relatedLinks[key]?.products?.map(
                                    (item, key) => (
                                      <option value={item.id} key={key}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                      {relatedLinks[key]?.typeLink === 2 && (
                        <>
                          <Col md="8">
                            <FormGroup>
                              <Label>Categoria</Label>
                              <InputGroup>
                                <select
                                  className="form-control"
                                  value={relatedLinks[key]?.categorySelected}
                                  onChange={(ev) => {
                                    handleListProduct(ev.target.value, key);
                                  }}
                                >
                                  <option value="">Selecione...</option>
                                  {relatedLinks[key]?.listSelect?.map(
                                    (item, key) => (
                                      <option value={item.id} key={key}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {relatedLinks[key]?.typeLink === 0 && (
                        <>
                          <Col md="6">
                            <FormGroup>
                              <Label>Titulo</Label>
                              <Input
                                type="text"
                                value={relatedLink.title}
                                onChange={(ev) => {
                                  const newData = [...relatedLinks];
                                  relatedLinks[key].title = ev.target.value;
                                  setRelatedLinks(newData);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <Label>Link</Label>
                              <InputGroup>
                                <Input
                                  type="link"
                                  placeholder="https://www.google.com.br"
                                  value={relatedLink.link}
                                  onChange={(ev) => {
                                    const newData = [...relatedLinks];
                                    relatedLinks[key].link = ev.target.value;
                                    setRelatedLinks(newData);
                                  }}
                                />
                                <select
                                  className="form-control col-md-4"
                                  value={relatedLink.target}
                                  onChange={(ev) => {
                                    const newData = [...relatedLinks];
                                    relatedLinks[key].target = ev.target.value;
                                    setRelatedLinks(newData);
                                  }}
                                >
                                  <option value="self">
                                    Abrir na mesma pagina
                                  </option>
                                  <option value="blank">
                                    Abrir em nova aba
                                  </option>
                                </select>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup>
                              <Label>Descrição</Label>
                              <textarea
                                className="form-control"
                                type="text"
                                value={relatedLink.description}
                                onChange={(ev) => {
                                  const newData = [...relatedLinks];
                                  relatedLinks[key].description =
                                    ev.target.value;
                                  setRelatedLinks(newData);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                  <CardFooter>
                    {key === relatedLinks.length - 1 &&
                      relatedLinks.length < 3 && (
                        <Button color="success" onClick={handleAdd}>
                          + ADICIONAR
                        </Button>
                      )}
                    {relatedLinks.length > 0 && (
                      <Button
                        className="ml-2"
                        color="danger"
                        onClick={() => handleRemove(key)}
                      >
                        - REMOVER
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              ))}
            </>
          )}

          {relatedLinks.length === 0 && (
            <Button color="success" onClick={handleAdd}>
              ADICIONAR LINK RELACIONADO
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default RelatedLinksComponent;
