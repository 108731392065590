import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BvIcon from "../../../lib/bvIcon";
import { bvIconEnum } from "../../../lib/bvIcon/BvIconEnum";
import { useState } from "react";

const IconComponentView = ({ isOpen, setIcon, setToggle }) => {
  const [listIcons, setListIcons] = useState(bvIconEnum);

  const handleFilter = (filter) => {
    if (filter === "") {
      setListIcons(bvIconEnum);
      return;
    }

    let PATTERN = new RegExp(`${filter.toLocaleLowerCase()}`);
    setListIcons(
      listIcons.filter((str) => {
        return PATTERN.test(str.name.toLocaleLowerCase());
      })
    );
  };

  const handleSelected = (icon) => {
    setIcon(icon);
    setToggle(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} size="xl">
        <ModalHeader className="w-100">Selecionar icone</ModalHeader>
        <ModalBody
          className="p-4"
          style={{ maxHeight: "600px", overflow: "auto" }}
        >
          <Card className="shadow">
            <CardBody>
              <FormGroup>
                <Label>Buscar</Label>
                <Input onChange={(ev) => handleFilter(ev.target.value)} />
              </FormGroup>
            </CardBody>
          </Card>
          <Card className="shadow mt-4">
            <CardBody>
              <Row>
                {listIcons.map((icon) => (
                  <Col md="4" className="p-2">
                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => handleSelected(icon.name)}
                    >
                      <BvIcon name={icon.name} /> {icon.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter><Button color="danger" onClick={() => setToggle(false)}>Sair</Button></ModalFooter>
      </Modal>
    </>
  );
};

export default IconComponentView;
