import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {
  createBankService,
  getByIdBankService,
  updateBankService,
} from "../../services/Register/BankService";
import Swal from "sweetalert2";
import getBase64 from "../../utils/input-base64";

const BankRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [infos, setInfos] = useState([
    {
      description: "",
    },
  ]);
  const [register, setRegister] = useState({
    id: null,
    name: null,
    codeFebraban: null,
    linkAccessBank: null,
    urlRateTable: null,
    description: null,
  });

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (!params?.id || params?.id <= 0) {
      handleInsert();
    } else {
      handleUpdate();
    }
  };

  const handleInsert = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createBankService({ ...register, infos: infos })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          codeFebraban: null,
          linkAccessBank: null,
          description: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/financeira");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateBankService(register.id, { ...register, infos: infos })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          codeFebraban: null,
          linkAccessBank: null,
          description: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/financeira");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetBank = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdBankService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister({
          ...data.resultSet,
        });
        setInfos(data?.resultSet?.infos ?? [{ description: "" }]);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleAdd = () => {
    setInfos([...infos, { description: "" }]);
  };

  const handleRemove = (key) => {
    if (infos.length <= 1) {
      Swal.fire({
        icon: "warning",
        title: "Ops...",
        text: "Você não pode remover todas as descrições!",
      });
      return;
    }

    setInfos(
      infos.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  useEffect(() => {
    handleGetBank();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Financeira</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="codeFebraban">
                    cod. febraban
                  </Label>
                  <Input
                    type="text"
                    id="codeFebraban"
                    name="codeFebraban"
                    value={register.codeFebraban}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        codeFebraban: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="9">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      setRegister({ ...register, name: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="linkAccessBank">
                    Link Financeira <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="url"
                    id="linkAccessBank"
                    name="linkAccessBank"
                    value={register.linkAccessBank}
                    placeholder="https://"
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        linkAccessBank: ev.target.value,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="linkAccessBank">
                    Link Tabela de tarifas
                  </Label>
                  <Input
                    type="url"
                    id="urlRateTable"
                    name="urlRateTable"
                    value={register.urlRateTable}
                    placeholder="https://"
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        urlRateTable: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Descrição da financeira
                  </Label>
                  <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 mt-3 mb-3 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h4>Info(s)</h4>
                  </CardHeader>
                  <CardBody>
                    {infos.map((info, key) => (
                      <Card key={key} className="shadow mb-2">
                        <CardBody>
                          <FormGroup>
                            <InputGroup>
                              <textarea
                                className="form-control"
                                type="text"
                                placeholder=""
                                id="description"
                                name="description"
                                value={info.description}
                                onChange={(ev) => {
                                  const newInfos = [...infos];
                                  infos[key].description = ev.target.value;
                                  setInfos(newInfos);
                                }}
                              />
                              {infos.length > 1 && (
                                <InputGroupAddon addonType="append">
                                  <Button
                                    color="danger"
                                    onClick={() => handleRemove(key)}
                                  >
                                    -
                                  </Button>
                                </InputGroupAddon>
                              )}

                              {key === infos.length - 1 && (
                                <InputGroupAddon addonType="append">
                                  <Button color="success" onClick={handleAdd}>
                                    +
                                  </Button>
                                </InputGroupAddon>
                              )}
                            </InputGroup>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    ))}
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h5>Upload</h5>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      {register?.logo && (
                        <div className="p-4 rounded border border-1 mb-4">
                          <img
                            id="img"
                            height="100"
                            alt="logo-financeira"
                            src={register.logo}
                          />
                        </div>
                      )}
                      <FormGroup>
                        <Label for="name">
                          Logo <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="file"
                          id="nome"
                          name="nome"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                logo: result.base64,
                              });
                            });
                          }}
                          required={!register?.id}
                        />
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/financeira")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default BankRegisterView;
