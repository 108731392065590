import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {
  createBranchService,
  getByIdBranchService,
  updateBranchService,
} from "../../services/Register/BranchService";
import Swal from "sweetalert2";
import stateBrazil from "../../utils/stateBrazil";
import { phoneMask } from "../../utils/mask";

const BranchRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    name: null,
    phone: null,
    zipCode: null,
    address: null,
    city: null,
    state: null,
  });
  const [phones, setPhones] = useState([{ number: "" }]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateBranchService(register.id, { ...register, phones: phones })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          codeFebraban: null,
          linkAccessBank: null,
          description: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/filial");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createBranchService({ ...register, phones: phones })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          phone: null,
          zipCode: null,
          address: null,
          city: null,
          state: null,
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/filial");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdBranchService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
        setPhones(data.resultSet?.phones ?? [{ phone: "" }]);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleAdd = () => {
    setPhones([...phones, { number: "" }]);
  };

  const handleRemove = (key) => {
    if (phones.length <= 1) {
      Swal.fire({
        icon: "warning",
        title: "Ops...",
        text: "Você não pode remover todos os telefones!",
      });
      return;
    }

    setPhones(
      phones.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  useEffect(() => {
    handleGetById();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Filial</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      setRegister({ ...register, name: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="urlGoogleMaps">
                    URL Google Maps
                  </Label>
                  <Input
                    type="url"
                    id="urlGoogleMaps"
                    name="urlGoogleMaps"
                    value={register.urlGoogleMaps}
                    onChange={(ev) => {
                      setRegister({ ...register, urlGoogleMaps: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 mt-3 mb-3 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h4>Telefones</h4>
                  </CardHeader>
                  <CardBody>
                    {phones.map((phone, key) => (
                      <Card key={key} className="shadow mb-2">
                        <CardBody>
                          <FormGroup>
                            <InputGroup>
                              <Input
                                type="text"
                                placeholder="(00) 00000-0000"
                                id="phone"
                                name="phone"
                                value={phone.number}
                                maxLength={20}
                                onChange={(ev) => {
                                  const newPhones = [...phones];
                                  phones[key].number = phoneMask(
                                    ev.target.value
                                  );
                                  setPhones(newPhones);
                                }}
                              />
                              {phones.length > 1 && (
                                <InputGroupAddon addonType="append">
                                  <Button
                                    color="danger"
                                    onClick={() => handleRemove(key)}
                                  >
                                    -
                                  </Button>
                                </InputGroupAddon>
                              )}

                              {key === phones.length - 1 && (
                                <InputGroupAddon addonType="append">
                                  <Button color="success" onClick={handleAdd}>
                                    +
                                  </Button>
                                </InputGroupAddon>
                              )}
                            </InputGroup>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card className="border-0 mt-3 mb-3 shadow">
              <CardHeader className="border-0 bg-white">
                <h4>Endereço</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup>
                      <Label for="zipCode">
                        CEP <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        placeholder="00000-000"
                        id="zipCode"
                        name="zipCode"
                        value={register.zipCode}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            zipCode: ev.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                      <Label for="address">
                        Endereço <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="address"
                        name="address"
                        value={register.address}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            address: ev.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="district">
                        Bairro <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="district"
                        name="district"
                        value={register.district}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            district: ev.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="city">
                        Cidade <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="city"
                        name="city"
                        value={register.city}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            city: ev.target.value,
                          });
                        }}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="state">
                        Estado <span className="text-danger">*</span>
                      </Label>
                      <select
                        className="form-control"
                        id="state"
                        name="state"
                        value={register.state}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            state: ev.target.value,
                          });
                        }}
                        required
                      >
                        <option value="">Selecione</option>
                        {stateBrazil.map((state, key) => (
                          <option value={state} key={key}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/filial")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default BranchRegisterView;
