import api from "../api";

export const createProductService = async (bank) => {
  return await api.post("/api/v1/product", bank);
};

export const updateProductService = async (id, bank) => {
  return await api.put(`/api/v1/product/${id}`, bank);
};

export const listProductService = async (filter) => {
  return await api.get("/api/v1/product", {
    params: filter,
  });
};

export const getByIdProductService = async (id) => {
  return await api.get(`/api/v1/product/${id}`);
};

export const inactiveActiveProductService = async (id) => {
  return await api.put(`/api/v1/product/active-inactive/${id}`);
};

export const deleteProductService = async (id) => {
  return await api.delete(`/api/v1/product/${id}`);
};
