import React, { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CardRegisterComponent from "./CardRegisterComponent";

const DragAndDrop = ({ items, setItems }) => {
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", parseInt(item.order));
    console.log("handleDragStart", item);
    setDraggedItem(item);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropTargetItem) => {
    e.preventDefault();
    const draggedItemId = Number(e.dataTransfer.getData("text/plain"), 10);

    if (draggedItemId !== Number(dropTargetItem.order)) {
      const updatedItems = items.map((item) => {
        if (Number(item.order) === draggedItemId) {
          return { ...item, order: Number(dropTargetItem.order) };
        }
        if (Number(item.order) === parseInt(dropTargetItem.order)) {
          return { ...item, order: Number(draggedItem.order) };
        }
        return item;
      });

      setItems(
        [...updatedItems].sort((a, b) => Number(a.order) - Number(b.order))
      );
      setDraggedItem(null);
    }
  };

  const handleRemove = (key) => {
    const result = items.filter((link, keyLink) => {
      return key !== keyLink;
    });
    setItems(result);
  };

  const sortedItems = [...items].sort(
    (a, b) => Number(a.order) - Number(b.order)
  );

  const handleAddNewBenefit = () => {
    setItems([
      ...items,
      {
        id: null,
        title: null,
        description: null,
        icon: "checkCircle",
        order: items.length + 1,
      },
    ]);
  };

  return (
    <Card className="shadow">
      <CardHeader className="border-0 bg-white">
        <h2>Vantagens</h2>
      </CardHeader>
      <CardBody>
        <Row onDragOver={handleDragOver}>
          {sortedItems.length > 0 &&
            items.map((item, key) => (
              <Col
                md="4"
                key={key}
                draggable
                onDragStart={(e) => handleDragStart(e, item)}
                onDragOver={() => handleDragOver}
                onDrop={(e) => handleDrop(e, item)}
                style={{
                  backgroundColor: draggedItem === item ? "#f0f0f0" : "white",
                  cursor: "pointer",
                }}
                className="pt-2 pb-2"
              >
                <CardRegisterComponent
                  item={items[key]}
                  setItem={(e) => {
                    items[key] = e;
                    setItems(items);
                  }}
                  key={key}
                  handleRemove={() => handleRemove(key)}
                />
              </Col>
            ))}
          <Col md="3" className="pt-2 pb-2">
            <Card
              className="shadow h-100 bg-light"
              onClick={handleAddNewBenefit}
              style={{ cursor: "pointer" }}
            >
              <CardBody className="d-flex flex-column justify-content-between w-100 h-100">
                <div className=" w-100 h-100">
                  <span className="d-flex justify-content-center align-items-center w-100 h-100">
                    <i className={"eva eva-plus-outline"} />
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DragAndDrop;
