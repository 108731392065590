import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  CardFooter,
  Button,
  Form,
  Badge,
  Input,
} from "reactstrap";
import {
  listBankService,
  updateOrderService,
} from "../../services/Register/BankService";
import Swal from "sweetalert2";

const BankOrder = () => {
  const history = useHistory();
  const [draggedItem, setDraggedItem] = useState(null);
  const [items, setItems] = useState([]);

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", parseInt(item.orderBank));
    setDraggedItem(item);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropTargetItem) => {
    e.preventDefault();
    const draggedItemId = Number(e.dataTransfer.getData("text/plain"), 10);

    if (draggedItemId !== Number(dropTargetItem.orderBank)) {
      const updatedItems = items.map((item) => {
        if (Number(item.orderBank) === draggedItemId) {
          return { ...item, orderBank: Number(dropTargetItem.orderBank) };
        }
        if (Number(item.orderBank) === parseInt(dropTargetItem.orderBank)) {
          return { ...item, orderBank: Number(draggedItem.orderBank) };
        }
        return item;
      });

      setItems(
        [...updatedItems].sort(
          (a, b) => Number(a.orderBank) - Number(b.orderBank)
        )
      );
      setDraggedItem(null);
    }
  };

  const sortedItems = [...items].sort(
    (a, b) => Number(a.orderBank) - Number(b.orderBank)
  );

  const handleList = () => {
    setItems([]);
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listBankService({
      active: 1,
      limit: 3000,
      closeInfo: true,
    })
      .then(({ data }) => {
        Swal.close();

        if (!data?.resultSet || data?.resultSet.length <= 0) {
          return;
        }
        setItems(data?.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSave = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateOrderService(items)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro alterado com sucesso",
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Form>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <h3>Financeiras - Ordenar</h3>
          </CardHeader>
          <CardBody>
            <Card className="shadow">
              <CardBody
                onDragOver={handleDragOver}
                style={{ maxHeight: 400, overflow: "auto" }}
              >
                {sortedItems.length > 0 &&
                  items.map((item, key) => (
                    <Card
                      md="3"
                      key={key}
                      draggable
                      onDragStart={(e) => handleDragStart(e, item)}
                      onDragOver={() => handleDragOver}
                      onDrop={(e) => handleDrop(e, item)}
                      style={{
                        backgroundColor:
                          draggedItem === item ? "#f0f0f0" : "white",
                        cursor: "pointer",
                      }}
                      className="shadow mb-2"
                    >
                      <CardBody>
                        <span className="float-right">
                          <i className={"eva eva-move-outline"} />
                        </span>
                        <Badge color="primary" className="font-12">
                          <Input
                            value={Number(items[key].orderBank)}
                            className="bg-transparent text-white border-0 text-center"
                            style={{width: '50px'}}
                            onChange={(e) => {
                              const newItems = [...items];
                              items[key].orderBank = Number(e.target.value);
                              setItems(newItems);
                            }}
                            onBlur={(e) => {
                              let newItems = [
                                ...items.sort(
                                  (a, b) =>
                                    Number(a.orderBank) - Number(b.orderBank)
                                ),
                              ];

                              for (let x = 0; x < newItems.length; x++) {
                                newItems[x].orderBank = x + 1;
                              }

                              setItems(newItems);
                            }}
                          />
                        </Badge>{" "}
                        - {item.name}
                      </CardBody>
                    </Card>
                  ))}
              </CardBody>
            </Card>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2" onClick={handleSave}>
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/financeira")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default BankOrder;
