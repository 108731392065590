import React, { FC, ReactNode } from 'react'

interface GroupContentProps {
	flow?: 'col' | 'row'
	justify?:
		| 'start'
		| 'center'
		| 'end'
		| 'between'
		| 'around'
		| 'evenly'
	align?:
		| 'start'
		| 'center'
		| 'end'
		| 'stretch'
		| 'baseline'
	gap?: '0' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
	children: ReactNode
	className?: string
}

const GroupContent: FC<GroupContentProps> = ({
	flow = 'col',
	justify = 'start',
	align = 'start',
	gap = 'md',
	children,
	className,
}) => {
	return (
		<div
			className={`ds-flex flow-${flow}-nw justify-${justify} align-${align} gap-${gap} ${className}`}>
			{children}
		</div>
	)
}

export default GroupContent
