export const bvIconEnum = [
  { name: "accountBox" },
  { name: "agriculture" },
  { name: "arrowCircleRight" },
  { name: "arrowSelectorTool" },
  { name: "autorenew" },
  { name: "bevipentagono" },
  { name: "beviprotege" },
  { name: "blocks" },
  { name: "bolt" },
  { name: "book" },
  { name: "campaign" },
  { name: "chalkboardUser" },
  { name: "chartLineUp" },
  { name: "cloud" },
  { name: "construction" },
  { name: "creditCard" },
  { name: "cube" },
  { name: "directionsCar" },
  { name: "doorOpen" },
  { name: "eco" },
  { name: "emergencyHome" },
  { name: "event" },
  { name: "faceRetouchingNatural" },
  { name: "falaBevi" },
  { name: "fgts" },
  { name: "groups" },
  { name: "handArchive" },
  { name: "handCreditCard" },
  { name: "handHouse" },
  { name: "handMoney" },
  { name: "handshakeBusiness" },
  { name: "headsetMic" },
  { name: "heart" },
  { name: "home" },
  { name: "houseCar" },
  { name: "key" },
  { name: "label" },
  { name: "lightbulb" },
  { name: "localShipping" },
  { name: "luggage" },
  { name: "medal" },
  { name: "money" },
  { name: "personRemove" },
  { name: "person" },
  { name: "place" },
  { name: "questionAnswer" },
  { name: "sackMoney" },
  { name: "savingPigs" },
  { name: "search" },
  { name: "smartDisplay" },
  { name: "smartphone" },
  { name: "snap" },
  { name: "solarPanel" },
  { name: "swipeRight" },
  { name: "target" },
  { name: "verified" },
  { name: "warning" },
  { name: "watchLater" },
  { name: "wifi" },
  { name: "work" },
  { name: "alternateEmail" },
  { name: "article" },
  { name: "assignment" },
  { name: "bank" },
  { name: "bookmark" },
  { name: "chat" },
  { name: "checkCircle" },
  { name: "contactMail" },
  { name: "contactPhone" },
  { name: "contactSupport" },
  { name: "corporate" },
  { name: "description" },
  { name: "draft" },
  { name: "drafts" },
  { name: "emailContent" },
  { name: "facebook" },
  { name: "factCheck" },
  { name: "feed" },
  { name: "helpCenter" },
  { name: "help" },
  { name: "importContacts" },
  { name: "instagram" },
  { name: "launch" },
  { name: "linkedin" },
  { name: "listAlt" },
  { name: "lock" },
  { name: "lockPerson" },
  { name: "mail" },
  { name: "markAsUnread" },
  { name: "medalHonor" },
  { name: "mood" },
  { name: "permPhoneMsg" },
  { name: "phoneInTalk" },
  { name: "pieChart" },
  { name: "queryStats" },
  { name: "quiz" },
  { name: "school" },
  { name: "sentimentDissatisfied" },
  { name: "sentimentSatisfied" },
  { name: "sentimentVerySatisfied" },
  { name: "settings" },
  { name: "shieldWithHouse" },
  { name: "speakerNotes" },
  { name: "speed" },
  { name: "sportsMotorsports" },
  { name: "thumbUp" },
  { name: "trophy" },
  { name: "youtube" },
  { name: "acute" },
  { name: "addTask" },
  { name: "approvalDelegation" },
  { name: "autoGraph" },
  { name: "badge" },
  { name: "bigDebts" },
  { name: "brightnessHight" },
  { name: "build" },
  { name: "calculate" },
  { name: "chartLineDown" },
  { name: "contentCut" },
  { name: "crane" },
  { name: "database" },
  { name: "desktop" },
  { name: "devices" },
  { name: "directionsBoat" },
  { name: "donutSmall" },
  { name: "dove" },
  { name: "elderly" },
  { name: "emergency" },
  { name: "eventAvailable" },
  { name: "eventBusy" },
  { name: "freeCancellation" },
  { name: "gasoline" },
  { name: "gold" },
  { name: "healthAndSafety" },
  { name: "heapSnapshotLarge" },
  { name: "homeMoving" },
  { name: "hourglassTop" },
  { name: "interestFree" },
  { name: "iventory" },
  { name: "laptopChart" },
  { name: "laptop" },
  { name: "laptopMoney" },
  { name: "laptopPlus" },
  { name: "leaderboard" },
  { name: "lockOpen" },
  { name: "moneyPlus" },
  { name: "moneyTime" },
  { name: "nutrition" },
  { name: "orderApprove" },
  { name: "overview" },
  { name: "paid" },
  { name: "pendingActions" },
  { name: "pix" },
  { name: "priceCheck" },
  { name: "psychiatry" },
  { name: "repaid" },
  { name: "security" },
  { name: "sell" },
  { name: "shoppingBasket" },
  { name: "stethoscope" },
  { name: "storefront" },
  { name: "surpriseBox" },
  { name: "taxPaper" },
  { name: "timelapse" },
  { name: "today" },
  { name: "verifiedUser" },
  { name: "wallet" },
];
