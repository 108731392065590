import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  createProductService,
  getByIdProductService,
  listProductService,
  updateProductService,
} from "../../services/Register/ProductService";
import getBase64 from "../../utils/input-base64";
import { listCategoryService } from "../../services/Register/CategoryService";
import { listBankService } from "../../services/Register/BankService";
import IconComponentView from "../benefit/component/IconComponentView";
import RelatedLinksComponent from "../../components/Form/RelatedLinks";
import RelatedQuestionComponent from "../../components/Form/RelatedQuestion";
import DragAndDrop from "../question/component/ComponentDragAndDrop";
import ReactQuill from "react-quill";

const ProductRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [tabActive, setTabActive] = useState(1);
  const [register, setRegister] = useState({
    id: null,
    name: null,
    path: null,
    description: null,
    whatIsDescription: null,
    banks: [],
    benefits: [],
  });
  const [banks, setBanks] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [showIconForWhom, setShowIconForWhom] = useState(false);
  const [showIconWhatIs, setShowIconWhatIs] = useState(false);
  const [forWhomDescription, setForWhomDescription] = useState([]);
  const [description, setDescription] = useState("");

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleListBank = () => {
    listBankService({limit: 500}).then(({ data }) => {
      if (!data?.resultSet) return;
      setBanks(
        data?.resultSet.map((register) => {
          return {
            label: register.name,
            value: register.id,
          };
        })
      );
    });
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    //forWhomDescription
    updateProductService(register.id, {
      ...register,
      forWhomDescriptions: forWhomDescription,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          benefits: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/produto");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createProductService({
      ...register,
      forWhomDescriptions: forWhomDescription,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          benefits: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/produto");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdProductService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
        setDescription(data?.resultSet?.description ?? "");
        if (
          data?.resultSet?.forWhomDescriptions &&
          data?.resultSet?.forWhomDescriptions.length > 0
        ) {
          setForWhomDescription(
            data?.resultSet?.forWhomDescriptions ?? [
              {
                id: null,
                icon: "",
                title: "Para quem é",
                description: "",
              },
            ]
          );
        }

        handleListBank();
        handleListProducts();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListProducts = () => {
    listCategoryService().then(({ data }) => {
      if (!data?.resultSet) return;
      setCategorys(
        data?.resultSet.map((register) => {
          return {
            label: register.name,
            value: register.id,
          };
        })
      );
    });
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleAdd = () => {
    setForWhomDescription([
      ...forWhomDescription,
      { id: null, title: "", description: "" },
    ]);
  };

  const handleRemove = (key) => {
    if (forWhomDescription.length < 0) {
      Swal.fire({
        icon: "warning",
        title: "Ops...",
        text: "Você não pode remover todos os agrupamentos!",
      });
      return;
    }

    setForWhomDescription(
      forWhomDescription.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  useEffect(() => {
    handleGetById();
    if (!params?.id) {
      handleListBank();
      handleListProducts();
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Produto</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      let path = ev.target.value.toLowerCase();
                      path = path
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                      path = path.replace(/[^a-zA-Z ]/g, "");
                      path = path.replace(/[ ]/g, "-");
                      setRegister({
                        ...register,
                        name: ev.target.value,
                        path: path,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="path">
                    URL <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="path"
                    name="path"
                    value={register.path}
                    onChange={(ev) => {
                      setRegister({ ...register, path: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="path">
                    Financeira <span className="text-danger">*</span>
                  </Label>
                  <Select
                    value={register?.banks}
                    isMulti
                    name="bank"
                    options={banks}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(ev) => {
                      setRegister({ ...register, banks: ev });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="path">Categorias</Label>
                  <Select
                    value={register?.categorys}
                    isMulti
                    name="categorys"
                    options={categorys}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(ev) => {
                      setRegister({ ...register, categorys: ev });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  {/* <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  ></textarea> */}

                  <ReactQuill
                    value={description}
                    style={{ height: 350 }}
                    className="mb-5"
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image", "video"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "video",
                    ]}
                    onChange={(ev) => {
                      setDescription(ev);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="metaDescription">
                    Descrição curta (meta-description)
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="metaDescription"
                    name="metaDescription"
                    value={register.metaDescription}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        metaDescription: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow mt-4">
                  <CardHeader className="border-0 bg-white">
                    <h5>Upload</h5>
                  </CardHeader>
                  <CardBody>
                    <Col md="12">
                      <FormGroup>
                        <Label for="name">Imagem Principal</Label>
                        {register?.imgMain && (
                          <div className="p-4 rounded border border-1 mb-4">
                            <img
                              id="img"
                              height="100"
                              alt="logo-financeira"
                              src={register.imgMain}
                            />
                          </div>
                        )}
                        <Input
                          type="file"
                          id="nome"
                          name="nome"
                          accept="image/*"
                          onChange={(ev) => {
                            const file = ev.target.files[0] || null;
                            getBase64(file).then((result) => {
                              setRegister({
                                ...register,
                                imgMain: result.base64,
                              });
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Nav className="mt-5" tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 1 ? `active` : ""}
                  onClick={() => setTabActive(1)}
                >
                  Vantagens
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 2 ? `active` : ""}
                  onClick={() => setTabActive(2)}
                >
                  O que é
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 3 ? `active` : ""}
                  onClick={() => setTabActive(3)}
                >
                  Para quem é
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 4 ? `active` : ""}
                  onClick={() => setTabActive(4)}
                >
                  Links Relacionados
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 5 ? `active` : ""}
                  onClick={() => setTabActive(5)}
                >
                  Dúvidas relacionadas
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={1}>
                <DragAndDrop
                  items={register.benefits}
                  setItems={(e) => {
                    setRegister({
                      ...register,
                      benefits: e,
                    });
                  }}
                />
              </TabPane>
              <TabPane tabId={2}>
                <Card className="border-0 shadow mt-4">
                  <CardHeader className="border-0 bg-white">
                    <h5>O que é</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label for="whatIsIcon">Icone</Label>
                          <InputGroup>
                            <Input
                              value={register?.whatIsIcon ?? ""}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  whatIsIcon: ev.target.value,
                                });
                              }}
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                className="info"
                                onClick={() => {
                                  setShowIconWhatIs(true);
                                }}
                              >
                                Buscar
                              </Button>
                              <IconComponentView
                                setIcon={(e) => {
                                  setRegister({
                                    ...register,
                                    whatIsIcon: e,
                                  });
                                }}
                                setToggle={(e) => {
                                  setShowIconWhatIs(e);
                                }}
                                isOpen={showIconWhatIs}
                              />
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label for="whatIsDescription">Titulo</Label>
                          <Input
                            type="text"
                            id="whatIsDescription"
                            name="whatIsDescription"
                            value={register.whatIsTitle}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                whatIsTitle: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label for="whatIsDescription">
                            Descrição <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            className="form-control"
                            type="text"
                            id="whatIsDescription"
                            name="whatIsDescription"
                            value={register.whatIsDescription}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                whatIsDescription: ev.target.value,
                              });
                            }}
                            required
                          ></textarea>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label for="name">Imagem</Label>
                          {register?.whatIsImg && (
                            <div className="p-4 rounded border border-1 mb-4">
                              <img
                                id="img"
                                height="100"
                                alt="logo-whatIsImg"
                                src={register.whatIsImg}
                              />
                            </div>
                          )}
                          <Input
                            type="file"
                            id="nome"
                            name="nome"
                            accept="image/*"
                            onChange={(ev) => {
                              const file = ev.target.files[0] || null;
                              getBase64(file).then((result) => {
                                setRegister({
                                  ...register,
                                  whatIsImg: result.base64,
                                });
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={3}>
                <Card className="border-0 mt-3 mb-3 shadow">
                  <CardHeader className="border-0 bg-white">
                    <h5>Para quem é</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label for="icon">Icone</Label>
                          <IconComponentView
                            setIcon={(e) => {
                              setRegister({
                                ...register,
                                forWhomIcon: e,
                              });
                            }}
                            setToggle={(e) => {
                              setShowIconForWhom(e);
                            }}
                            isOpen={showIconForWhom}
                          />
                          <InputGroup>
                            <Input
                              value={register.forWhomIcon}
                              onChange={(ev) => {
                                setRegister({
                                  ...register,
                                  forWhomIcon: ev.target.value,
                                });
                              }}
                            />
                            <InputGroupAddon addonType="append">
                              <Button
                                className="info"
                                onClick={() => {
                                  setShowIconForWhom(true);
                                }}
                              >
                                Buscar
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Titulo</Label>
                          <Input
                            type="text"
                            value={register.forWhomTitle}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                forWhomTitle: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label>Descrição</Label>
                      <textarea
                        className="form-control"
                        value={register.forWhomDescription}
                        onChange={(ev) => {
                          setRegister({
                            ...register,
                            forWhomDescription: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>

                    {forWhomDescription.length === 0 && (
                      <Button color="success" onClick={handleAdd}>
                        + ADICIONAR AGRUPAMENTO
                      </Button>
                    )}

                    {forWhomDescription.map((whomDescription, key) => (
                      <Card className="border-0 shadow mb-3" key={key}>
                        <CardHeader className="bg-white">
                          <h5>Grupos</h5>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label>Titulo</Label>
                                <Input
                                  type="text"
                                  value={whomDescription.title}
                                  onChange={(ev) => {
                                    const newForWhomDescription = [
                                      ...forWhomDescription,
                                    ];
                                    forWhomDescription[key].title =
                                      ev.target.value;
                                    setForWhomDescription(
                                      newForWhomDescription
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Label>Descrição</Label>
                            <textarea
                              className="form-control"
                              value={whomDescription.description}
                              onChange={(ev) => {
                                const newForWhomDescription = [
                                  ...forWhomDescription,
                                ];
                                forWhomDescription[key].description =
                                  ev.target.value;
                                setForWhomDescription(newForWhomDescription);
                              }}
                            />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          {key === forWhomDescription.length - 1 &&
                            forWhomDescription.length < 4 && (
                              <Button color="success" onClick={handleAdd}>
                                + ADICIONAR
                              </Button>
                            )}
                          {forWhomDescription.length > 0 && (
                            <Button
                              className="ml-2"
                              color="danger"
                              onClick={() => handleRemove(key)}
                            >
                              - REMOVER
                            </Button>
                          )}
                        </CardFooter>
                      </Card>
                    ))}
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId={4}>
                <RelatedLinksComponent
                  relatedLinks={register?.relatedLinks ?? []}
                  setRelatedLinks={(e) => {
                    setRegister({
                      ...register,
                      relatedLinks: e,
                    });
                  }}
                />
              </TabPane>
              <TabPane tabId={5}>
                <RelatedQuestionComponent
                  relatedQuestions={register?.relatedQuestions ?? []}
                  setRelatedQuestions={(e) => {
                    setRegister({
                      ...register,
                      relatedQuestions: e,
                    });
                  }}
                />
              </TabPane>
            </TabContent>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/produto")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default ProductRegisterView;
