import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  CardFooter,
  Button,
  Label,
  Input,
  Alert,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import {
  deleteBannerHomeService,
  inactiveActiveBannerHomeService,
  listBannerHomeService,
} from "../../services/Register/BannerHomeService.js";

const Dashboard = () => {
  const history = useHistory();
  const [filter, setFilter] = useState({
    title: null,
    status: null,
    limitStart: 0,
    limit: 10,
  });
  const [list, setList] = useState([]);
  const [showBtnMore, setShowBtnMore] = useState(false);

  const handleList = (resetLimit) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (resetLimit > 0) {
      setList([]);
    }

    const newFilter = filter;

    listBannerHomeService({
      ...newFilter,
      limitStart: resetLimit > 0 ? 0 : filter.limitStart,
    })
      .then(({ data }) => {
        Swal.close();
        setShowBtnMore(data?.resultSet.length >= 10);

        if (!data?.resultSet || data?.resultSet.length <= 0) {
          return;
        }

        if (resetLimit > 0) {
          setList(data?.resultSet);
          setFilter({
            ...filter,
            limitStart: data.resultSet.length,
          });
          return;
        }

        setList([...list, ...data?.resultSet]);
        setFilter({
          ...filter,
          limitStart: filter.limitStart + data.resultSet.length,
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleUpdate = (id) => {
    history.push(`/admin/banner/${id}`);
  };

  const handleActiveInactive = (id) => {
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    inactiveActiveBannerHomeService(id)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro alterado com sucesso",
        });

        handleList();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };
  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja excluir? será irreversível",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, excluir",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Excluindo",
        text: "Por favor, aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      deleteBannerHomeService(id)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Registro removido com sucesso",
          });

          handleList();
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    handleList(1);
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col className="pr-grid-col" xs={12} lg={12}>
            <Card className="border-0 mb-5 mt-3 shadow">
              <CardHeader className="border-0 bg-white">
                <h4 className="headline-2">FILTRO - BANNER TELA PRINCIPAL</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Titulo</Label>
                      <Input
                        type="text"
                        onChange={(ev) => {
                          setFilter({ ...filter, title: ev.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>Status</Label>
                      <select
                        className="form-control"
                        onChange={(ev) => {
                          setFilter({ ...filter, active: ev.target.value });
                        }}
                      >
                        <option value="">Todos</option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="border-0 bg-white">
                <Button
                  className="mr-2"
                  color="default"
                  onClick={() => {
                    setList([]);
                    handleList(1);
                  }}
                >
                  BUSCAR
                </Button>
                <Button
                  color="success"
                  onClick={() => history.push("/admin/banner/novo")}
                >
                  NOVO REGISTRO
                </Button>
              </CardFooter>
            </Card>
            {list.length <= 0 && (
              <Alert color="primary" className="text-primary">
                Não foram identificados registros para esta consulta
              </Alert>
            )}
            {list.length > 0 && (
              <Card className="shadow">
                <CardHeader className="border-0 bg-white">
                  Lista de Notas oficiais
                </CardHeader>
                <Table
                  className={`table-striped table-borderless table-hover`}
                  responsive
                >
                  <thead>
                    <tr>
                      <th>DATA CADASTRO</th>
                      <th>TITULO</th>
                      <th>STATUS</th>
                      <th>OPÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((register, key) => (
                      <tr key={key}>
                        <td>{register.createdAt}</td>
                        <td>{register.title}</td>
                        <td>
                          {register.active == 1 ? (
                            <Badge color="success">ATIVO</Badge>
                          ) : (
                            <Badge color="danger">INATIVO</Badge>
                          )}
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>Opções</DropdownToggle>

                            <DropdownMenu>
                              <DropdownItem header>Opções</DropdownItem>
                              <DropdownItem
                                onClick={() => handleUpdate(register.id)}
                              >
                                ALTERAR
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  handleActiveInactive(register.id)
                                }
                              >
                                {register.active > 0 ? "INATIVAR" : "ATIVAR"}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => handleDelete(register.id)}
                              >
                                EXCLUIR
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {showBtnMore && (
                  <Button className="w-100" onClick={() => handleList()}>
                    Carregar mais
                  </Button>
                )}
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
