import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import Swal from "sweetalert2";
import QuestionCategorySelectorComponent from "../../../pages/question/component/QuestionCategorySelectorComponent";
import { getByQuestionGroupIdQuestionService } from "../../../services/Register/QuestionService";
import { useState } from "react";

const RelatedQuestionComponent = ({
  relatedQuestions = [],
  setRelatedQuestions,
}) => {
  const handleAdd = () => {
    setRelatedQuestions([
      ...relatedQuestions,
      { id: null, title: "", description: "", link: "" },
    ]);
  };

  const handleRemove = (key) => {
    if (relatedQuestions.length < 0) {
      Swal.fire({
        icon: "warning",
        title: "Ops...",
        text: "Você não pode remover todos os agrupamentos!",
      });
      return;
    }

    setRelatedQuestions(
      relatedQuestions.filter((link, keyLink) => {
        return key !== keyLink;
      })
    );
  };

  const handleGetQuestionByGroupId = (groupId, key) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByQuestionGroupIdQuestionService(groupId)
      .then(({ data: { resultSet } }) => {
        console.log(resultSet);
        Swal.close();
        const newData = [...relatedQuestions];
        relatedQuestions[key].questions = resultSet;
        console.log(relatedQuestions);
        setRelatedQuestions(newData);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <>
      <Card className="border-0 shadow mt-4">
        <CardHeader className="border-0 bg-white">
          <h5>Dúvidas relacionados</h5>
        </CardHeader>
        <CardBody>
          {relatedQuestions.length > 0 && (
            <>
              {relatedQuestions.map((relatedLink, key) => (
                <Card className="shadow mb-4" key={`relatedLinks${key}`}>
                  <CardBody>
                    <QuestionCategorySelectorComponent
                      isCreate={false}
                      setGroupId={(e) => {
                        const newData = [...relatedQuestions];
                        relatedQuestions[key].questionGroupId = e;
                        setRelatedQuestions(newData);
                        handleGetQuestionByGroupId(e, key);
                      }}
                    />
                    {relatedLink?.questions?.length > 0 && (
                      <>
                        <FormGroup>
                          <Label>Dúvidas</Label>
                          <select
                            className="form-control"
                            value={relatedLink.questionId}
                            onChange={(ev) => {
                              const newData = [...relatedQuestions];
                              relatedQuestions[key].questionId =
                                ev.target.value;
                              setRelatedQuestions(newData);
                            }}
                          >
                            <option value="">Selecione</option>
                            {relatedLink?.questions.map((question, key) => (
                              <option value={question.id}>
                                {question.title}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </>
                    )}
                  </CardBody>
                  <CardFooter>
                    {key === relatedQuestions.length - 1 &&
                      relatedQuestions.length < 4 && (
                        <Button color="success" onClick={handleAdd}>
                          + ADICIONAR
                        </Button>
                      )}
                    {relatedQuestions.length > 0 && (
                      <Button
                        className="ml-2"
                        color="danger"
                        onClick={() => handleRemove(key)}
                      >
                        - REMOVER
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              ))}
            </>
          )}

          {relatedQuestions.length === 0 && (
            <Button color="success" onClick={handleAdd}>
              ADICIONAR DÚVIDA
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default RelatedQuestionComponent;
