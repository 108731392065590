import api from "../api";

export const createQuestionService = async (bank) => {
  return await api.post("/api/v1/question", bank);
};

export const updateQuestionService = async (id, bank) => {
  return await api.put(`/api/v1/question/${id}`, bank);
};

export const listQuestionService = async (filter) => {
  return await api.get("/api/v1/question", {
    params: filter,
  });
};

export const getByIdQuestionService = async (id) => {
  return await api.get(`/api/v1/question/${id}`);
};

export const getByQuestionGroupIdQuestionService = async (id) => {
  return await api.get(`/api/v1/question/group/${id}`);
};

export const inactiveActiveQuestionService = async (id) => {
  return await api.put(`/api/v1/question/active-inactive/${id}`);
};

export const deleteQuestionService = async (id) => {
  return await api.delete(`/api/v1/question/${id}`);
};

export const listAllQuestionCategoryService = async () => {
  return await api.get(`/api/v1/question-category`);
};
export const createQuestionCategoryService = async (data) => {
  return await api.post(`/api/v1/question-category`, data);
};
export const updateQuestionCategoryService = async (id, data) => {
  return await api.put(`/api/v1/question-category/${id}`, data);
};

export const listAllQuestionSubCategoryService = async (questionCategoryId) => {
  return await api.get(`/api/v1/question-subcategory/${questionCategoryId}`);
};
export const createQuestionSubCategoryService = async (data) => {
  return await api.post(`/api/v1/question-subcategory`, data);
};
export const updateQuestionSubCategoryService = async (id, data) => {
  return await api.put(`/api/v1/question-subcategory/${id}`, data);
};

export const listAllGroupService = async (questionSubCategoryId) => {
  return await api.get(`/api/v1/question-group/${questionSubCategoryId}`);
};
export const createGroupService = async (data) => {
  return await api.post(`/api/v1/question-group`, data);
};
export const updateGroupService = async (id, data) => {
  return await api.put(`/api/v1/question-group/${id}`, data);
};
