import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import IconComponentView from "../../benefit/component/IconComponentView";

const WhatIsComponent = ({
  register,
  setRegister,
  setShowIconWhatIs,
  showIconWhatIs,
  getBase64,
}) => {
  return (
    <>
      <Card className="border-0 shadow mt-4">
        <CardHeader className="border-0 bg-white">
          <h5>O que é</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label for="whatIsIcon">Icone</Label>
                <InputGroup>
                  <Input
                    value={register?.whatIsIcon ?? ""}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        whatIsIcon: ev.target.value,
                      });
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      className="info"
                      onClick={() => {
                        setShowIconWhatIs(true);
                      }}
                    >
                      Buscar
                    </Button>
                    <IconComponentView
                      setIcon={(e) => {
                        setRegister({
                          ...register,
                          whatIsIcon: e,
                        });
                      }}
                      setToggle={(e) => {
                        setShowIconWhatIs(e);
                      }}
                      isOpen={showIconWhatIs}
                    />
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="whatIsTitle">Titulo</Label>
                <Input
                  type="text"
                  id="whatIsTitle"
                  name="whatIsTitle"
                  value={register.whatIsTitle}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      whatIsTitle: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label for="whatIsHatTag">Etiqueta (Hat-tag)</Label>
                <Input
                  type="text"
                  id="whatIsHatTag"
                  name="whatIsHatTag"
                  value={register.whatIsHatTag}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      whatIsHatTag: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="whatIsDescription">
                  Descrição <span className="text-danger">*</span>
                </Label>
                <textarea
                  className="form-control"
                  type="text"
                  id="whatIsDescription"
                  name="whatIsDescription"
                  value={register.whatIsDescription}
                  onChange={(ev) => {
                    setRegister({
                      ...register,
                      whatIsDescription: ev.target.value,
                    });
                  }}
                  required
                ></textarea>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="name">Imagem</Label>
                {register?.whatIsImg && (
                  <div className="p-4 rounded border border-1 mb-4">
                    <img
                      id="img"
                      height="100"
                      alt="logo-whatIsImg"
                      src={register.whatIsImg}
                    />
                  </div>
                )}
                <Input
                  type="file"
                  id="nome"
                  name="nome"
                  accept="image/*"
                  onChange={(ev) => {
                    const file = ev.target.files[0] || null;
                    getBase64(file).then((result) => {
                      setRegister({
                        ...register,
                        whatIsImg: result.base64,
                      });
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default WhatIsComponent;
