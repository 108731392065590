import React, {
	FC,
	HTMLAttributes,
	ReactNode,
	createContext,
	useState,
} from 'react'

import styles from './Root.module.scss'

const ThemeContext = createContext('')

interface RootProps extends HTMLAttributes<HTMLDivElement> {
	theme:
		| 'one'
		| 'two'
		| 'three'
		| 'four'
		| 'five'
		| 'six'
		| 'seven'
		| 'eight'
		| 'nine'
		| 'ten'
		| 'eleven'
		| 'twelve'
		| 'thirteen'
		| 'fourteen'
		| 'fifteen'
		| 'sixteen'
	children: ReactNode
	className?: string
}

const Root: FC<RootProps> = ({
	theme,
	children,
	className,
	...props
}) => {
	return (
		<ThemeContext.Provider value={theme}>
			<section
				data-theme={theme}
				className={`${styles.Root} ${className}`}
				{...props}>
				<div className='bv-container-lg h-100'>
					<div className='bv-row align-stretch h-100 ps-relative gap-lg'>
						{children}
					</div>
				</div>
			</section>
		</ThemeContext.Provider>
	)
}

export { Root, ThemeContext }
