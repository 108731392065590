import api from "../api";

export const createBankService = async (bank) => {
  return await api.post("/api/v1/bank", bank);
};

export const updateBankService = async (id, bank) => {
  return await api.put(`/api/v1/bank/${id}`, bank);
};

export const listBankService = async (bank) => {
  return await api.get("/api/v1/bank", {
    params: bank,
  });
};

export const getByIdBankService = async (id) => {
  return await api.get(`/api/v1/bank/${id}`);
};

export const inactiveActiveBankService = async (id) => {
  return await api.put(`/api/v1/bank/active-inactive/${id}`);
};

export const deleteBankService = async (id) => {
  return await api.delete(`/api/v1/bank/${id}`);
};

export const updateOrderService = async (itens) => {
  return await api.put(`/api/v1/bank-order`, itens);
};
