import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import { listProductService } from "../../services/Register/ProductService";
import getBase64 from "../../utils/input-base64";
import {
  createQuestionService,
  getByIdQuestionService,
  updateQuestionService,
} from "../../services/Register/QuestionService";
import QuestionCategorySelectorComponent from "./component/QuestionCategorySelectorComponent";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const QuestionRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [register, setRegister] = useState({
    id: null,
    title: null,
    path: null,
    description: null,
    shortDescription: null,
    products: [],
  });
  const [description, setDescription] = useState("");
  const [questionCategoryId, setQuestionCategoryId] = useState(null);
  const [questionSubCategoryId, setQuestionSubCategoryId] = useState(null);
  const [questionGroupId, setQuestionGroupId] = useState(null);

  const [products, setProducts] = useState([]);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateQuestionService(register.id, {
      ...register,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          path: null,
          description: null,
          products: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/duvida");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createQuestionService({
      ...register,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          title: null,
          path: null,
          description: null,
          products: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/duvida");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdQuestionService(params?.id)
      .then(({ data: { resultSet } }) => {
        Swal.close();
        setRegister(resultSet);
        setDescription(resultSet?.description ?? "");

        setQuestionCategoryId(resultSet?.relation?.questionCategoryId ?? null);
        setQuestionSubCategoryId(
          resultSet?.relation?.questionSubCategoryId ?? null
        );
        setQuestionGroupId(resultSet?.relation?.questionGroupId ?? null);

        handleListProducts();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListProducts = () => {
    listProductService().then(({ data }) => {
      if (!data?.resultSet) return;
      setProducts(
        data?.resultSet.map((register) => {
          return {
            label: register.name,
            value: register.id,
          };
        })
      );
    });
  };

  useEffect(() => {
    handleGetById();
    if (!params?.id) {
      handleListProducts();
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Dúvida</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12">
                <QuestionCategorySelectorComponent
                  groupId={register.questionGroupId ?? ""}
                  setGroupId={(e) => {
                    setRegister({
                      ...register,
                      questionGroupId: e,
                    });
                  }}
                  category={questionCategoryId}
                  subCategory={questionSubCategoryId}
                  questionGroup={questionGroupId}
                />
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="title">
                    Titulo <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={register.title}
                    onChange={(ev) => {
                      let path = ev.target.value.toLowerCase();
                      path = path
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                      path = path.replace(/[^a-zA-Z ]/g, "");
                      path = path.replace(/[ ]/g, "-");
                      setRegister({
                        ...register,
                        title: ev.target.value,
                        path: path,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="path">
                    URL <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="path"
                    name="path"
                    value={register.path}
                    onChange={(ev) => {
                      setRegister({ ...register, path: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Resposta Curta <span className="text-danger">*</span>
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="shortDescription"
                    name="shortDescription"
                    value={register.shortDescription}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        shortDescription: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  {/* <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  ></textarea> */}

                  <ReactQuill
                    value={description}
                    style={{ height: 350 }}
                    className="mb-5"
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image", "video"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "video",
                    ]}
                    onChange={(ev) => {
                      setDescription(ev);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/duvida")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
};

export default QuestionRegisterView;
