import api from "../api";

export const createBenefitService = async (bank) => {
  return await api.post("/api/v1/benefit", bank);
};

export const updateBenefitService = async (id, bank) => {
  return await api.put(`/api/v1/benefit/${id}`, bank);
};

export const listBenefitService = async (filter) => {
  return await api.get("/api/v1/benefit", {
    params: filter,
  });
};

export const getByIdBenefitService = async (id) => {
  return await api.get(`/api/v1/benefit/${id}`);
};

export const inactiveActiveBenefitService = async (id) => {
  return await api.put(`/api/v1/benefit/active-inactive/${id}`);
};

export const deleteBenefitService = async (id) => {
  return await api.delete(`/api/v1/benefit/${id}`);
};
