import api from "../api";

export const createBranchService = async (bank) => {
  return await api.post("/api/v1/branch", bank);
};

export const updateBranchService = async (id, bank) => {
  return await api.put(`/api/v1/branch/${id}`, bank);
};

export const listBranchService = async (filter) => {
  return await api.get("/api/v1/branch", {
    params: filter,
  });
};

export const getByIdBranchService = async (id) => {
  return await api.get(`/api/v1/branch/${id}`);
};

export const inactiveActiveBranchService = async (id) => {
  return await api.put(`/api/v1/branch/active-inactive/${id}`);
};

export const deleteBranchService = async (id) => {
  return await api.delete(`/api/v1/branch/${id}`);
};
