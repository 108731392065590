import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { createQuestionSubCategoryService } from "../../../services/Register/QuestionService";
import { useState } from "react";
import Swal from "sweetalert2";

const SubCategoryModalComponent = ({
  categoryId,
  isOpen,
  toggle,
  handleList,
}) => {
  const [register, setRegister] = useState({ title: null });
  const handleSubmit = () => {
    Swal.fire({
      title: "Salvando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createQuestionSubCategoryService({
      ...register,
      questionCategoryId: categoryId,
    })
      .then(() => {
        toggle(false);
        handleList();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Sub Categoria</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Titulo</Label>
          <Input
            type="text"
            value={register.title}
            onChange={(ev) => {
              setRegister({
                ...register,
                title: ev.target.value,
              });
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSubmit}>
          Salvar
        </Button>
        <Button
          color="danger"
          onClick={() => {
            toggle(false);
            handleList();
          }}
        >
          Sair
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SubCategoryModalComponent;
