import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import {
  listAllGroupService,
  listAllQuestionCategoryService,
  listAllQuestionSubCategoryService,
} from "../../../services/Register/QuestionService";
import Swal from "sweetalert2";
import { useEffect } from "react";
import CategoryModalComponent from "./CategoryModalComponent";
import SubCategoryModalComponent from "./SubCategoryModalComponent";
import GroupModalComponent from "./GroupModalComponent";

const QuestionCategorySelectorComponent = ({
  groupId,
  setGroupId,
  category,
  subCategory,
  questionGroup,
  isCreate = true,
}) => {
  const [listCategory, setListCategory] = useState([]);
  const [listSubCategory, setSubListCategory] = useState([]);
  const [listGroup, setListGroup] = useState([]);
  const [modalCategory, setModalCategory] = useState(false);
  const [modalSubCategory, setModalSubCategory] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [categoryId, setCategoryId] = useState(category);
  const [subCategoryId, setSubCategoryId] = useState(subCategory);
  const [questionGroupId, setQuestionGroupId] = useState(questionGroup);

  const handleListCategory = () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde,",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listAllQuestionCategoryService()
      .then(({ data: { resultSet } }) => {
        Swal.close();
        setListCategory(resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListSubCategory = (id) => {
    if (!id) return;

    setCategoryId(id);

    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde,",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listAllQuestionSubCategoryService(id)
      .then(({ data: { resultSet } }) => {
        Swal.close();
        setSubListCategory(resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListGroup = (id) => {
    if (!id) return;

    setSubCategoryId(id);

    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde,",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    listAllGroupService(id)
      .then(({ data: { resultSet } }) => {
        Swal.close();
        setListGroup(resultSet);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleListCategory();
  }, []);

  useEffect(() => {
    if (category > 0) {
      handleListSubCategory(category);
    }

    if (subCategory > 0) {
      handleListGroup(subCategory);
    }
  }, [category, subCategory]);

  return (
    <>
      <Card className="shadow mb-4">
        <CardBody>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label>Categoria</Label>
                <InputGroup>
                  <select
                    className="form-control"
                    value={categoryId}
                    onChange={(ev) => handleListSubCategory(ev.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {listCategory.length > 0 && (
                      <>
                        {listCategory.map((register, key) => (
                          <option key={key} value={register.id}>
                            {register.title}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {isCreate && (
                    <InputGroupAddon addonType="append">
                      <Button
                        color="success"
                        onClick={() => setModalCategory(true)}
                      >
                        +
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Sub-Categoria</Label>
                <InputGroup>
                  <select
                    className="form-control"
                    value={subCategoryId}
                    onChange={(ev) => handleListGroup(ev.target.value)}
                  >
                    <option value="">Selecione...</option>
                    {listSubCategory.length > 0 && (
                      <>
                        {listSubCategory.map((register, key) => (
                          <option key={key} value={register.id}>
                            {register.title}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {isCreate && (
                    <InputGroupAddon addonType="append">
                      <Button
                        color="success"
                        onClick={() => setModalSubCategory(true)}
                      >
                        +
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label>Grupo</Label>
                <InputGroup>
                  <select
                    className="form-control"
                    value={questionGroupId}
                    onChange={(ev) => {
                      setGroupId(ev.target.value);
                      setQuestionGroupId(questionGroupId);
                    }}
                  >
                    <option value="">Selecione...</option>
                    {listGroup.length > 0 && (
                      <>
                        {listGroup.map((register, key) => (
                          <option key={key} value={register.id}>
                            {register.title}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {isCreate && (
                    <InputGroupAddon addonType="append">
                      <Button
                        color="success"
                        onClick={() => setModalGroup(true)}
                      >
                        +
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <CategoryModalComponent
        isOpen={modalCategory}
        toggle={setModalCategory}
        handleList={handleListCategory}
      />
      <SubCategoryModalComponent
        isOpen={modalSubCategory}
        toggle={setModalSubCategory}
        categoryId={categoryId}
        handleList={() => {
          handleListSubCategory(categoryId);
        }}
      />
      <GroupModalComponent
        isOpen={modalGroup}
        toggle={setModalGroup}
        questionSubCategoryId={subCategoryId}
        handleList={() => {
          handleListGroup(subCategoryId);
        }}
      />
    </>
  );
};

export default QuestionCategorySelectorComponent;
