import React, {
	FC,
	HTMLAttributes,
	ReactNode,
	useContext,
} from 'react'
import { ThemeContext } from './Root'

import styles from './Content.module.scss'

interface ContentProps
	extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode
	className?: string
}

export const Content: FC<ContentProps> = ({
	children,
	className,
	...props
}) => {
	const themeContext = useContext(ThemeContext)
	return (
		<div
			data-theme={themeContext}
			className={`bv-col is-05 ${styles.Column} ds-flex flow-col-nw justify-center align-start`}
			{...props}>
			<div
				className={`${styles.Content} ds-flex flow-col-nw justify-center align-start gap-lg`}>
				{children}
			</div>
		</div>
	)
}
