import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import IconComponentView from "../../benefit/component/IconComponentView";
import BvIcon from "../../../lib/bvIcon";
import ReactQuill from "react-quill";

const CardRegisterComponent = ({ setItem, item, handleRemove }) => {
  const [showIconWhatIs, setShowIconWhatIs] = useState(false);
  return (
    <>
      <Card className="shadow h-100">
        <CardBody className="d-flex flex-column justify-content-between">
          <Row>
            <Col md="9">
              <FormGroup>
                <Label>Título</Label>
                <Input
                  type="text"
                  className="border-0 p-0"
                  placeholder="Informar título"
                  value={item.title}
                  onChange={(ev) => {
                    setItem({
                      ...item,
                      title: ev.target.value,
                    });
                  }}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="3" className="text-right">
              <Button
                onClick={() => {
                  setShowIconWhatIs(true);
                }}
                color="none"
                className="p-0 m-0"
              >
                <BvIcon name={item.icon ?? "checkCircle"} />
              </Button>

              <IconComponentView
                setIcon={(e) => {
                  setItem({
                    ...item,
                    icon: e,
                  });
                }}
                setToggle={(e) => {
                  setShowIconWhatIs(e);
                }}
                isOpen={showIconWhatIs}
              />
            </Col>
          </Row>
          <FormGroup style={{ minHeight: "280px" }}>
            <Label>Descrição</Label>
            {/* <textarea
              className="form-control border-0 p-0"
              placeholder="Informar descrição"
              value={item.description}
              onChange={(ev) => {
                setItem({
                  ...item,
                  description: ev.target.value,
                });
              }}
              required
            ></textarea> */}
            <ReactQuill
              value={item.description}
              style={{ height: 160 }}
              className="mb-5"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              onChange={(ev) => {
                setItem({
                  ...item,
                  description: ev,
                });
              }}
            />
          </FormGroup>
        </CardBody>
        <CardFooter>
          <Button size="lg" className="w-100" onClick={() => handleRemove()}>
            Remover
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default CardRegisterComponent;
