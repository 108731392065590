import { Root } from './Root'
import { Content } from './Content'
import { HatTag } from './HatTag'
import { Title } from './Title'
import { Description } from './Description'
import { Button } from './Button'
import { ImageContent } from './ImageContent'
import { Square } from './Square'

export const BannerHome = {
	Root,
	Content,
	HatTag,
	Title,
	Description,
	Button,
	ImageContent,
	Square,
}
