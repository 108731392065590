import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  FormGroup,
  Input,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  CardFooter,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import { listBankService } from "../../services/Register/BankService";
import getBase64 from "../../utils/input-base64";
import {
  createCategoryService,
  getByIdCategoryService,
  updateCategoryService,
} from "../../services/Register/CategoryService";
import FormBenefitComponentView from "../benefit/component/FormBenefitComponentView";
import solid from "../../lib/bvIcon/variant/solid";
import IconComponentView from "../benefit/component/IconComponentView";
import RelatedLinksComponent from "../../components/Form/RelatedLinks";
import RelatedQuestionComponent from "../../components/Form/RelatedQuestion";
import DragAndDrop from "../question/component/ComponentDragAndDrop";
import WhatIsComponent from "./component/whatIsComponent";
import ReactQuill from "react-quill";

const CategoryRegisterView = () => {
  const history = useHistory();
  const params = useParams();
  const [tabActive, setTabActive] = useState(1);
  const [register, setRegister] = useState({
    id: null,
    name: null,
    path: null,
    description: null,
    whatIsDescription: null,
    whatIsIcon: null,
    forWhomDescription: null,
    banks: [],
    benefits: [],
    relatedQuestions: [],
  });
  const [banks, setBanks] = useState([]);

  const [icon, setIcon] = useState("");
  const [openIcon, setOpenIcon] = useState(false);
  const [showIconWhatIs, setShowIconWhatIs] = useState(false);
  const [productDescription, setProductDescription] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (ev) => {
    ev.preventDefault();
    handleInsert();
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!params?.id || params?.id <= 0) return;
    Swal.fire({
      title: "Alterando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    updateCategoryService(register.id, {
      ...register,
      productDescription: productDescription,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          whatIsIcon: null,
          forWhomDescription: null,
          benefits: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/categoria");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleInsert = () => {
    if (params?.id && params?.id > 0) return;

    Swal.fire({
      title: "Salvando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    createCategoryService({
      ...register,
      productDescription: productDescription,
      description: description,
    })
      .then(({ data }) => {
        setRegister({
          id: null,
          name: null,
          path: null,
          description: null,
          whatIsDescription: null,
          forWhomDescription: null,
          benefits: [],
        });

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Registro cadastrado com sucesso",
        }).then(() => {
          history.push("/admin/categoria");
        });
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetById = () => {
    if (!params?.id || params?.id <= 0) {
      return;
    }
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getByIdCategoryService(params?.id)
      .then(({ data }) => {
        Swal.close();
        setRegister(data.resultSet);
        setProductDescription(data?.resultSet?.productDescription ?? "");
        setDescription(data?.resultSet?.description ?? "");
        handleListBank();
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListBank = () => {
    listBankService().then(({ data }) => {
      if (!data?.resultSet) return;
      setBanks(
        data?.resultSet.map((register) => {
          return {
            label: register.name,
            value: register.id,
          };
        })
      );
    });
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    handleGetById();
    if (!params?.id) {
      handleListBank();
    }
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card className="border-0 shadow">
          <CardHeader className="border-0 bg-white">
            <span className="text-danger float-right">
              Os campos com * são obrigatórios
            </span>
            <h3>Categoria</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label for="name">
                    Nome <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="nome"
                    name="nome"
                    value={register.name}
                    onChange={(ev) => {
                      let path = ev.target.value.toLowerCase();
                      path = path
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "");
                      path = path.replace(/[^a-zA-Z ]/g, "");
                      path = path.replace(/[ ]/g, "-");
                      setRegister({
                        ...register,
                        name: ev.target.value,
                        path: path,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="path">
                    URL <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="path"
                    name="path"
                    value={register.path}
                    onChange={(ev) => {
                      setRegister({ ...register, path: ev.target.value });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label for="icon">Icone</Label>
                  <InputGroup>
                    <Input
                      value={register.icon}
                      onChange={(ev) => {
                        setRegister({
                          ...register,
                          icon: ev.target.value,
                        });
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        className="info"
                        onClick={() => {
                          setOpenIcon(true);
                        }}
                      >
                        Buscar
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label for="description">
                    Descrição <span className="text-danger">*</span>
                  </Label>
                  {/* <textarea
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={register.description}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        description: ev.target.value,
                      });
                    }}
                    required
                  ></textarea> */}

                  <ReactQuill
                    value={description}
                    style={{ height: 350 }}
                    className="mb-5"
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image", "video"],
                        ["clean"],
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "video",
                    ]}
                    onChange={(ev) => {
                      setDescription(ev);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label for="metaDescription">
                    Descrição Curta (meta-description)
                  </Label>
                  <Input
                    type="text"
                    id="metaDescription"
                    name="metaDescription"
                    value={register.metaDescription}
                    onChange={(ev) => {
                      setRegister({
                        ...register,
                        metaDescription: ev.target.value,
                      });
                    }}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Card className="border-0 shadow mt-4">
                  <CardHeader className="border-0 bg-white">
                    <h5>Seção Produtos</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label for="productTitle">Titulo</Label>
                          <Input
                            type="text"
                            id="productTitle"
                            name="productTitle"
                            value={register.productTitle}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                productTitle: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label for="productDescription">Descrição</Label>
                          {/* <textarea
                            className="form-control"
                            type="text"
                            id="productDescription"
                            name="productDescription"
                            value={register.productDescription}
                            onChange={(ev) => {
                              setRegister({
                                ...register,
                                productDescription: ev.target.value,
                              });
                            }}
                            required
                          ></textarea> */}

                          <ReactQuill
                            value={productDescription}
                            style={{ height: 350 }}
                            className="mb-5"
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, false] }],
                                [
                                  "bold",
                                  "italic",
                                  "underline",
                                  "strike",
                                  "blockquote",
                                ],
                                [
                                  { list: "ordered" },
                                  { list: "bullet" },
                                  { indent: "-1" },
                                  { indent: "+1" },
                                ],
                                ["link", "image", "video"],
                                ["clean"],
                              ],
                            }}
                            formats={[
                              "header",
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                              "list",
                              "bullet",
                              "indent",
                              "link",
                              "image",
                              "video",
                            ]}
                            onChange={(ev) => {
                              setProductDescription(ev);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card className="border-0 shadow mt-4">
              <CardHeader className="border-0 bg-white">
                <h5>Upload</h5>
              </CardHeader>
              <CardBody>
                <Col md="12">
                  <FormGroup>
                    <Label for="name">Imagem Principal</Label>
                    {register?.imgMain && (
                      <div className="p-4 rounded border border-1 mb-4">
                        <img
                          id="img"
                          height="100"
                          alt="logo-financeira"
                          src={register.imgMain}
                        />
                      </div>
                    )}
                    <Input
                      type="file"
                      id="nome"
                      name="nome"
                      accept="image/*"
                      onChange={(ev) => {
                        const file = ev.target.files[0] || null;
                        getBase64(file).then((result) => {
                          setRegister({
                            ...register,
                            imgMain: result.base64,
                          });
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </CardBody>
            </Card>

            <Nav className="mt-5" tabs>
              <NavItem>
                <NavLink
                  className={tabActive === 1 ? `active` : ""}
                  onClick={() => setTabActive(1)}
                >
                  Vantagens
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 2 ? `active` : ""}
                  onClick={() => setTabActive(2)}
                >
                  O que é
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 3 ? `active` : ""}
                  onClick={() => setTabActive(3)}
                >
                  Links relacionados
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tabActive === 4 ? `active` : ""}
                  onClick={() => setTabActive(4)}
                >
                  Dúvidas relacionadas
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={tabActive}>
              <TabPane tabId={1}>
                <DragAndDrop
                  items={register.benefits}
                  setItems={(e) => {
                    setRegister({
                      ...register,
                      benefits: e,
                    });
                  }}
                />
              </TabPane>
              <TabPane tabId={2}>
                <WhatIsComponent
                  register={register}
                  setRegister={setRegister}
                  setShowIconWhatIs={setShowIconWhatIs}
                  showIconWhatIs={showIconWhatIs}
                  getBase64={getBase64}
                />
              </TabPane>
              <TabPane tabId={3}>
                <RelatedLinksComponent
                  relatedLinks={register?.relatedLinks ?? []}
                  setRelatedLinks={(e) => {
                    setRegister({
                      ...register,
                      relatedLinks: e,
                    });
                  }}
                />
              </TabPane>
              <TabPane tabId={4}>
                <RelatedQuestionComponent
                  relatedQuestions={register?.relatedQuestions ?? []}
                  setRelatedQuestions={(e) => {
                    setRegister({
                      ...register,
                      relatedQuestions: e,
                    });
                  }}
                />
              </TabPane>
            </TabContent>
          </CardBody>
          <CardFooter>
            <Button color="success" className="mr-2">
              SALVAR
            </Button>
            <Button
              color="secondary"
              onClick={() => history.push("/admin/categoria")}
            >
              VOLTAR
            </Button>
          </CardFooter>
        </Card>
      </Form>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cadastrar</ModalHeader>
        <ModalBody>
          <FormBenefitComponentView
            isModal={true}
            handleActionAfterSave={() => {
              toggle();
            }}
          />
        </ModalBody>
      </Modal>

      <IconComponentView
        setIcon={(e) => {
          setRegister({
            ...register,
            icon: e,
          });
        }}
        setToggle={setOpenIcon}
        isOpen={openIcon}
      />
    </>
  );
};

export default CategoryRegisterView;
